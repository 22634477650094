<template>
	<v-container v-if="enews.length == 0">{{ $t("enews.loading") }}</v-container>
	<v-container fluid v-else>
		<v-container>
            <h1>{{ $t("enews.title") }}</h1>
			<v-card>
				<v-tabs v-model="tab" centered grow icons-and-text>
					<v-tabs-slider></v-tabs-slider>
					<v-tab class="smaller" v-for="enew in enews" :key="enew.id">
						{{ enew.oznacenie }}<v-icon>{{ enew.icon }}</v-icon>
					</v-tab>
				</v-tabs>
					<!-- <v-tab class="smaller">Popis<v-icon>mdi-view-headline</v-icon></v-tab>
					<v-tab class="smaller">Vlastnosti<v-icon>mdi-file-key</v-icon></v-tab>
					<v-tab class="smaller">Parametre<v-icon>mdi-ballot</v-icon></v-tab>
					<v-tab class="smaller">Súbory<v-icon>mdi-download</v-icon></v-tab>
					<v-tab class="smaller">Objednávanie<v-icon>mdi-shopping</v-icon></v-tab> -->
					<!-- https://pictogrammers.github.io/@mdi/font/5.4.55/ -->
				<v-tabs-items v-model="tab">
					<v-tab-item v-for="enew in enews" :key="enew.id">
						<v-card class="flex d-flex flex-column" outlined tile elevation="24">
							<v-row dense>
								<v-col class="incenter">
									<v-avatar class="profile" size="120" tile>
										<v-img contain :src="build_img_url(enew.link)"></v-img>
									</v-avatar>
								</v-col>
							</v-row>
							<v-row>
								<v-col>
									<v-card-title class="gugi">{{ enew.nadpis }}</v-card-title>
									<v-card-text class="gugi" v-html="enew.podrobne"></v-card-text>
								</v-col>
							</v-row>
                            <!-- </div> -->
						</v-card>
					</v-tab-item>
				</v-tabs-items>
			</v-card>
		</v-container>
	</v-container>
</template>

<script>
import ApiService from "@/services/api_service";

export default {
	data: () => ({
        enews: [],
		drawer: false,
		group: null,
		
		tab: null,
	}),
	methods: {
        build_img_url(link) {
            return '/pictures/pics/enews/' + link
        }
	},
	watch: {
		group() {
			this.drawer = false;
		},
       
	},
	mounted() {
		ApiService.get("/enews/last/5").then((response) => {
			this.enews = response.data
		}).catch(() => {})
	},
	updated() {
	}
};
</script>

<style scoped>

.gugi {
	text-align: justify;
	word-break: normal;
}
.v-card {
	margin-bottom: 5px;
}
.v-tab {
	text-transform: none;
	font-size: 0.75rem;
}

.v-card__title {
	padding-top: 0px;
}

.container {
	padding-top: 0px;
}
.smaller {
	font-size: 0.58em;
}
.incenter {
	text-align: center;
}
.headline {
	font-size: 1.2rem !important;
	display: block;
	padding: 0;
}
.seda {
	background-color: rgb(245, 245, 245);
	margin: 0;
}
</style>
